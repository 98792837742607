<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.tabs.base')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="item.title"
                      :state="errors && errors.title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.title">
                      {{ errors.title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.url.label')"
                    label-for="url"
                  >
                    <b-form-input
                      id="url"
                      v-model="item.url"
                      :state="errors && errors.url ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.url">
                      {{ errors.url[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    :label="$t('form.sheet.label')"
                    label-for="sheet"
                  >
                    <b-form-input
                      id="sheet"
                      v-model="item.sheet"
                      :state="errors && errors.sheet ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.sheet">
                      {{ errors.sheet[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typesOptions"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.cron.label')"
                    label-for="cron"
                    :state="errors && errors.cron ? false : null"
                  >
                    <v-select
                      id="cron"
                      v-model="item.cron"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="cron"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.cron">
                      {{ errors.cron[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="6"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.language.label')"
                    label-for="language_id"
                    :state="errors && errors.language_id ? false : null"
                  >
                    <v-select
                      id="language_id"
                      v-model="item.language_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="languageOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="language_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.language_id">
                      {{ errors.language_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.translate.label')"
                    label-for="translate"
                    :state="errors && errors.translate ? false : null"
                  >
                    <v-select
                      id="translate"
                      v-model="item.translate"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="translate"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.translate">
                      {{ errors.translate[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.clear_model_problems.label')"
                    label-for="clear_model_problems"
                  >
                    <b-form-checkbox
                      id="clear_model_problems"
                      v-model="item.clear_model_problems"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.clear_model_problems">
                      {{ errors.clear_model_problems[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.tabs.columns')">
              <b-row>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.column_category.label')"
                    label-for="column_category"
                  >
                    <b-form-input
                      id="column_category"
                      v-model="item.column_category"
                      :state="errors && errors.column_category ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_category">
                      {{ errors.column_category[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.column_device.label')"
                    label-for="column_device"
                  >
                    <b-form-input
                      id="column_device"
                      v-model="item.column_device"
                      :state="errors && errors.column_device ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_device">
                      {{ errors.column_device[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.column_model.label')"
                    label-for="column_model"
                  >
                    <b-form-input
                      id="column_model"
                      v-model="item.column_model"
                      :state="errors && errors.column_model ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_model">
                      {{ errors.column_model[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.column_problem.label')"
                    label-for="column_problem"
                  >
                    <b-form-input
                      id="column_problem"
                      v-model="item.column_problem"
                      :state="errors && errors.column_problem ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_problem">
                      {{ errors.column_problem[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.column_model_problem_sku.label')"
                    label-for="column_model_problem_sku"
                  >
                    <b-form-input
                      id="column_model_problem_sku"
                      v-model="item.column_model_problem_sku"
                      :state="errors && errors.column_model_problem_sku ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_model_problem_sku">
                      {{ errors.column_model_problem_sku[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.column_model_problem_price.label')"
                    label-for="column_model_problem_price"
                  >
                    <b-form-input
                      id="column_model_problem_price"
                      v-model="item.column_model_problem_price"
                      :state="errors && errors.column_model_problem_price ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_model_problem_price">
                      {{ errors.column_model_problem_price[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.column_model_problem_image.label')"
                    label-for="column_model_problem_image"
                  >
                    <b-form-input
                      id="column_model_problem_image"
                      v-model="item.column_model_problem_image"
                      :state="errors && errors.column_model_problem_image ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_model_problem_image">
                      {{ errors.column_model_problem_image[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.column_model_problem_tips.label')"
                    label-for="column_model_problem_tips"
                  >
                    <b-form-input
                      id="column_model_problem_tips"
                      v-model="item.column_model_problem_tips"
                      :state="errors && errors.column_model_problem_tips ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_model_problem_tips">
                      {{ errors.column_model_problem_tips[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.tabs.continue')">
              <b-alert
                variant="info"
                show
                class="mb-3 p-1"
              >
                Буде виконано пропуск товарів, які підпадають хоча б під одне із правил нижче.
              </b-alert>
              <b-row>
                <b-col cols="12">
                  <div
                    v-if="item.continue_if"
                    class="repeater-form"
                  >
                    <b-row
                      v-for="(cont, index) in item.continue_if"
                      :id="'cont' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cont.f"
                            :options="ciFieldOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cont.c"
                            :options="ciCompareOptions"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group>
                          <b-form-input v-model="cont.v" />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-button
                          variant="danger"
                          block
                          @click="removeContinue(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="4">
                            <b-button
                              variant="primary"
                              block
                              @click="addSubContinue(index)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                class="mr-1"
                              />підумову
                            </b-button>
                          </b-col>
                          <b-col cols="8">
                            <div
                              v-if="cont.sub"
                              class="repeater-form"
                            >
                              <b-row
                                v-for="(sub, subIndex) in cont.sub"
                                :id="'sub' + index + subIndex"
                                :key="index + subIndex"
                                ref="row"
                              >
                                <b-col
                                  cols="12"
                                  md="3"
                                >
                                  <b-form-group>
                                    <v-select
                                      v-model="sub.f"
                                      :options="ciSubFieldOptions"
                                      :reduce="val => val.value"
                                      :clearable="false"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="3"
                                >
                                  <b-form-group>
                                    <v-select
                                      v-model="sub.c"
                                      :options="ciCompareOptions"
                                      :clearable="false"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="4"
                                >
                                  <b-form-group>
                                    <b-form-input v-model="sub.v" />
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="2"
                                >
                                  <b-button
                                    variant="danger"
                                    block
                                    @click="removeSubContinue(index, subIndex)"
                                  >
                                    <feather-icon
                                      icon="XIcon"
                                    />
                                  </b-button>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    block
                    @click="addContinue"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-1"
                    />умову
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
          <hr>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'imports-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import GlobalMixin from '@/mixins/GlobalMixin'
import StatusMixin from '@/mixins/StatusMixin'

export default {
  mixins: [StatusMixin, GlobalMixin],
  data() {
    return {
      item: {
        title: null,
        status: 'disabled',
        cron: 'disabled',
        translate: 'disabled',
        type: 'excel',
        url: null,
        sheet: 'Импорт',
        clear_model_problems: false,
        column_category: 'A',
        column_device: 'B',
        column_model: 'C',
        column_model_image: 'I',
        column_problem: 'G',
        column_model_problem_sku: 'D',
        column_model_problem_tips: 'E',
        column_model_problem_price: 'F',
        column_model_problem_image: 'H',
        language_id: 1,
        continue_if: [],
      },
      typesOptions: ['excel'],
      languageOptions: [],
      ciFieldOptions: [
        { label: 'Ціна', value: 'price' },
        { label: 'Категорія', value: 'category' },
        { label: 'Девайс', value: 'device' },
        { label: 'Модель', value: 'model' },
        { label: 'Проблема', value: 'problem' },
        { label: 'SKU', value: 'sku' },
      ],
      ciSubFieldOptions: [
        { label: 'Ціна', value: 'price' },
      ],
      ciCompareOptions: ['==', '!=', '>=', '<=', 'like', 'not_like'],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  beforeCreate() {
    this.$http('/api/languages')
      .then(response => {
        this.languageOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async onSubmit() {
      const data = {
        ...this.item,
      }

      this.$http.post('/api/admin/imports', data)
        .then(() => {
          router.replace({ name: 'imports-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addContinue() {
      this.item.continue_if.push({
        f: 'price',
        c: '==',
        v: 0,
        sub: [],
      })
    },
    removeContinue(index) {
      this.item.continue_if.splice(index, 1)
    },
    addSubContinue(index) {
      this.item.continue_if[index].sub.push({
        f: 'price',
        c: '==',
        v: 0,
      })
    },
    removeSubContinue(index, subIndex) {
      this.item.continue_if[index].sub.splice(subIndex, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
